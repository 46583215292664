import Vue from 'vue'
import VueRouter from 'vue-router'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [{
    path: '',
    name: 'index',
    redirect: '/index',
    meta: {
      keepAlive: false
    }
  },
  {
    path: "/swiper",
    name: 'swiper',
    component: () => import('../views/swiper'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: "/index",
    name: 'index',
    component: () => import('../views/index'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: "/productServices",
    name: 'productServices',
    component: () => import('../views/productServices'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: "/solutionThe",
    name: 'solutionThe',
    component: () => import('../views/solutionThe'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: "/documentationD",
    name: 'documentationD',
    component: () => import('../views/documentationD'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: "/schemePrice",
    name: 'schemePrice',
    component: () => import('../views/schemePrice'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: "/contactUs",
    name: 'contactUs',
    component: () => import('../views/contactUs'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: "/contactUsAdd",
    name: 'contactUsAdd',
    component: () => import('../views/contactUsAdd'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: "/ProtocolDetail",
    name: 'ProtocolDetail',
    component: () => import('../views/ProtocolDetail'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: "/serviceT",
    name: 'serviceT',
    component: () => import('../views/serviceT'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: "/markdown",
    name: 'markdown',
    component: () => import('../views/markdown'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: "/login",
    name: 'login',
    component: () => import('../views/login'),
    meta: {
      keepAlive: false
    },
  },
  // // 控制台 概览
  // {
  //   path: "/console",
  //   name: 'name',
  //   component: () => import('../views/console/index'),
  //   meta: {
  //     keepAlive: false
  //   },
  //   children:[
  //     {
  //       path: "",
  //       name:'console',
  //       component: () => import('../views/users/console')
  //     },
  //     {
  //       path: "",
  //       name:'console',
  //       component: () => import('../views/users/console')
  //     },
  //   ]
  // },
  // 控制台 概览
  {
    path: "/console",
    name: 'login',
    component: () => import('../views/console/index'),
    meta: {
      keepAlive: false
    },
  },
  // 控制台 财务管理 订单明细
  {
    path: "/orderFinance",
    name: 'Finance',
    component: () => import('../views/console/orderFinance'),
    meta: {
      keepAlive: false
    }
  },
  // 控制台 财务管理 费用明细
  {
    path: "/costDetails",
    name: 'costDetails',
    component: () => import('../views/console/costDetails'),
    meta: {
      keepAlive: false
    }
  },
  // 控制台 财务管理 发票管理
  {
    path: "/mInvoice",
    name: 'mInvoice',
    component: () => import('../views/console/mInvoice'),
    meta: {
      keepAlive: false
    }
  },
  // 控制台 账户信息
  {
    path: "/AccountInformation",
    name: 'AccountInformation',
    component: () => import('../views/console/AccountInformation'),
    meta: {
      keepAlive: false
    }
  },
  //公告列表
  {
    path: "/AnnouncementList",
    name: 'AnnouncementList',
    component: () => import('../views/console/AnnouncementList'),
    meta: {
      keepAlive: false
    }
  },

  // {
  //   path: "/layoutName",
  //   name: 'layoutName',
  //   component: () => import('../views/layout/index'),
  //   children: [
  //     {
  //       path: "",
  //       name: 'console',
  //       component: () => import('../views/console/index')
  //     },
  //     {
  //       path: "orderFinance",
  //       name: 'Finance',
  //       component: () => import('../views/console/orderFinance'),
  //       meta: {
  //         keepAlive: false
  //       }
  //     },

  //     // 控制台 财务管理 费用明细
  //     {
  //       path: "costDetails",
  //       name: 'costDetails',
  //       component: () => import('../views/console/costDetails'),
  //       meta: {
  //         keepAlive: false
  //       }
  //     },
  //     // 控制台 财务管理 发票管理
  //     {
  //       path: "mInvoice",
  //       name: 'mInvoice',
  //       component: () => import('../views/console/mInvoice'),
  //       meta: {
  //         keepAlive: false
  //       }
  //     },
  //   ]
  // },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})