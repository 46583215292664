const getters = {
  tabPosition: state => state.app.tabPosition,
  newsList: state => state.app.newsList,
  leftList: state => state.app.leftList,
  detailsItem: state => state.app.detailsItem,
  defaultName: state => state.app.defaultName,
  profileList: state => state.app.profileList,
  profileListtwo: state => state.app.profileListtwo,

}
export default getters
