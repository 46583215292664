import request from '../static/request'

//登录
export function login(data){
    return request({
        url:'/offical/login',
        method:'POST',
        data
    })
}

//验证码登录
export function CodeLogin(data){
    return request({
        url:'/offical/CodeLogin',
        method:'POST',
        data
    })
}
//测试登录//跳转
export function testLogin(data){
    return request({
        url:'/customer/testLogin',
        method:'POST',
        data
    })
}

//获取验证码
export function getCodeRe(data){
    return request({
        url:'/offical/sendMessageRegister',
        method:'post',
        data
    })
}


//获取验证码
export function getCode(data){
    return request({
        url:'/offical/sendMessage',
        method:'post',
        data
    })
}


//立即注册
export function getRegister(data){
    return request({
        url:'/offical/register',
        method:'post',
        data
    })
}

//菜单权限
export function menuAuth(data){
    return request({
        url:'/customer/menuAuth',
        method:'post',
        data
    })
}

//菜单权限地址
export function menuPath(data){
    return request({
        url:'/customer/menuPath',
        method:'post',
        data
    })
}

//获取用户信息
export function getUserInfo(data){
    return request({
        url:'/front/user/getInfo',
        method:'get',
        data
    })
}
//忘记密码
export function forgotPassword(data){
    return request({
        url:'/offical/forgotPassword',
        method:'POST',
        data
    })
}
//修改头像
export function updateAvter(data){
    return request({
        url:'/customer/updateAvter',
        method:'POST',
        data
    })
}
//修改密码
export function updatePassword(data){
    return request({
        url:'/customer/updatePassword',
        method:'POST',
        data
    })
}
// 官网--获取官网数据
export function getOfficalData(data){
    return request({
        url:'/offical/getOfficalData',
        method:'POST',
        data
    })
}
// 官网--获取官网低部数据
export function getOfficeBottomData(data){
    return request({
        url:'/offical/getOfficeBottomData',
        method:'POST',
        data
    })
}
// 官网--获取官网一级文章分类
export function getOfficeOneLevel(data){
    return request({
        url:'/offical/getOfficeOneLevel',
        method:'POST',
        data
    })
}
// 官网--获取官网顶部数据
export function getOfficeTopData(data){
    return request({
        url:'/offical/getOfficeTopData',
        method:'POST',
        data
    })
}
// 官网--获取官网二级文章分类
export function getOfficeTwoLevel(data){
    return request({
        url:'/offical/getOfficeTwoLevel',
        method:'POST',
        data
    })
}

// 配置接口

// 获取地图数据
export function getMapdata(data){
    return request({
        url:'/officeconfig/getMapdata',
        method:'POST',
        data
    })
}
// 获取资源模板图片
export function getTempFile(data){
    return request({
        url:'/officeconfig/getTempFile',
        method:'POST',
        data
    })
}
// 企业认证
export function companyCert(data){
    return request({
        url:'/customer/companyCert',
        method:'POST',
        data
    })
}
// 上传用户资料
export function updateData(data){
    return request({
        url:'/customer/updateData',
        method:'POST',
        data
    })
}

// 联系我们


// 获取关于我们的配置
export function getAboutUs(data){
    return request({
        url:'/officeconfig/getAboutUs',
        method:'POST',
        data
    })
}
// 控制台


// 用户信息(用户+客服信息)
export function accountinfo(data){
    return request({
        url:'/customer/accountinfo',
        method:'POST',
        data
    })
}

// 用户详情
export function accountinfoDetail(data){
    return request({
        url:'/customer/accountinfoDetail',
        method:'POST',
        data
    })
}
// 设置基础信息
export function updateBasicInfo(data){
    return request({
        url:'/customer/updateBasicInfo',
        method:'POST',
        data
    })
}
// 获取预警参数
export function getWarring(data){
    return request({
        url:'/customer/getWarring',
        method:'POST',
        data
    })
}
// 设置预警参数
export function setWarring(data){
    return request({
        url:'/customer/setWarring',
        method:'POST',
        data
    })
}
// 公告列表
export function getNoticeList(data){
    return request({
        url:'/customer/getNoticeList',
        method:'POST',
        data
    })
}
// 订单列表
export function orderList(data){
    return request({
        url:'/order/orderList',
        method:'POST',
        data
    })
}
// 订单取消
export function cancelOrder(data){
    return request({
        url:'/order/cancelOrder',
        method:'POST',
        data
    })
}
// 资源列表
export function resourceList(data){
    return request({
        url:'/order/resourceList',
        method:'POST',
        data
    })
}
// 已购资源列表
export function buyResources(data){
    return request({
        url:'/customer/buyResources',
        method:'POST',
        data
    })
}
// 使用情况
export function useage(data){
    return request({
        url:'/customer/useage',
        method:'POST',
        data
    })
}
// 创建订单
export function createOrder(data){
    return request({
        url:'/order/createOrder',
        method:'POST',
        data
    })
}
// 获取订单详情
export function getOrderDetail(data){
    return request({
        url:'/order/getOrderDetail',
        method:'POST',
        data
    })
}
// 获取线下支付信息
export function getofflinePayInfo(data){
    return request({
        url:'/officeconfig/getofflinePayInfo',
        method:'POST',
        data
    })
}
// 线下支付
export function offlinePayOrder(data){
    return request({
        url:'/order/offlinePayOrder',
        method:'POST',
        data
    })
}
// 微信支付
export function wxPayOrder(data){
    return request({
        url:'/order/wxPayOrder',
        method:'POST',
        data
    })
}
// 微信查询
export function wxPayQuery(data){
    return request({
        url:'/order/wxPayQuery',
        method:'POST',
        data
    })
}
// 支付宝支付
export function aliPayOrder(data){
    return request({
        url:'/order/aliPayOrder',
        method:'POST',
        data
    })
}
// 支付宝查询
export function aliPayQuery(data){
    return request({
        url:'/order/aliPayQuery',
        method:'POST',
        data
    })
}
// 获取平台用户协议
export function getArtilebyName(data){
    return request({
        url:'/offical/getArtilebyName',
        method:'POST',
        data
    })
}





// 发票
// 添加发票
export function addbill(data){
    return request({
        url:'/customer/addbill',
        method:'POST',
        data
    })
}
// 发票列表
export function listPain(data){
    return request({
        url:'/customer/billList',
        method:'POST',
        data
    })
}
// 费用列表
export function costList(data){
    return request({
        url:'/order/costList',
        method:'POST',
        data
    })
}
// 发票订单列表
export function billOrderList(data){
    return request({
        url:'/customer/billOrderList',
        method:'POST',
        data
    })
}
// 取消已开发票
export function cancelBill(data){
    return request({
        url:'/customer/cancelBill',
        method:'POST',
        data
    })
}
// 发票模版列表
export function listPainFP(data){
    return request({
        url:'/customer/billTempListPage',
        method:'POST',
        data
    })
}
// 添加发票模版
export function listPainAdd(data){
    return request({
        url:'/wd/billtempMgr/Add',
        method:'POST',
        data
    })
}
// 删除发票模版
export function listPainBatchDelete(data){
    return request({
        url:'/wd/billtempMgr/BatchDelete',
        method:'POST',
        data
    })
}
// 查询所有的发票模板
export function listPainBatchCustomerQuery(data){
    return request({
        url:'/customer/billTempList',
        method:'POST',
        data
    })
}
// 查询发票模板详情
export function listPainBatchGetInfo(data){
    return request({
        url:'/wd/billtempMgr/getInfo',
        method:'get',
        params:data
    })
}
// 修改发票模板
export function listPainBatchUpdate(data){
    return request({
        url:'/wd/billtempMgr/update',
        method:'POST',
        data
    })
}
// 开发文档菜单
export function GetAllCate(data){
    return request({
        url:'/portal/articletypeMgr/GetAllCate',
        method:'POST',
        data
    })
}
// 开发文档菜单 内容
export function getArtilebyTypeId(data){
    return request({
        url:'/offical/getArtilebyTypeId',
        method:'POST',
        data
    })
}
// 上传用户资料
export function updateDataUser(data){
    return request({
        url:'/customer/updateData',
        method:'POST',
        data
    })
}
// 上传用户资料审核状态
export function getUserReviewStata(data){
    return request({
        url:'/customer/getUserReviewStata',
        method:'POST',
        data
    })
}
// 页面埋点
export function getAddPoint(data){
    return request({
        url:'/offical/addPoint',
        method:'POST',
        data
    })
}
