<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import { getAboutUs } from '@/api/api'
export default {
  data() {
    return {
      isRouterAlive: true,
    }
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  watch:{
    '$route' (to, from) {
      console.log(to,from,'//////////');
    }
  },

  methods: {
    reload() {
      this.isRouterAlive = false;
      
      this.$nextTick(function () {
        // window.location.reload()
        this.isRouterAlive = true
      });
    },
    async getAboutUs() {
      let res = await getAboutUs()
      if (res.ResultCode == 0) {
        // this.aboutUs = res.Data
        let str = encodeURIComponent(JSON.stringify(res.Data))
        localStorage.setItem('about-us', str)
      }
    },
  },
  created() {
    this.getAboutUs()
  },
  mounted() {
  }
}
</script>
<style lang="scss">
@import './style/animation.scss';
@import "~bootstrap/scss/bootstrap";
@import "./style/swiper-bundle.min.css";
@import "./style/pubilc.css";

::v-deep {
  .el-table th.el-table__cell {
    font-size: 30px;
    font-family: 'PingFang SC';
    font-weight: 400;
    color: #333333;
  }
}

.nav_top {
  .text {

    position: relative;
    animation-name: example;
    animation-duration: 4s;
    animation-iteration-count: 1;
    animation-direction: alternate;
  }
}

.fsb_c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
}

html,
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
form,
input,
textarea,
th,
td,
select {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  font-family: "Microsoft YaHei";
  font-size: .875rem;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ul,
ol {
  list-style: none;
}

img {
  border: none;
  vertical-align: middle;
}

a {
  text-decoration: none;
  color: #232323;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
}

input,
textarea {
  outline: none;
  border: none;
}

textarea {
  resize: none;
  overflow: auto;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: ".";
  width: 0;
  visibility: hidden;
  display: block;
  clear: both;
  overflow: hidden;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.tl {
  text-align: left;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inline {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
</style>
