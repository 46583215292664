import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import Print from './static/print'
// svg
import './assets/icons'
// 动画
import 'animate.css'
import "wowjs/css/libs/animate.css"
import wow from 'wowjs'
Vue.prototype.$wow = wow
// 所有图片地址前缀
Vue.prototype.$baseUrl = 'http://2468.oss-cn-hangzhou.aliyuncs.com/'
// 上传图片地址
// Vue.prototype.$imgUrl = 'http://wd.9czn.cn/'//测试
// Vue.prototype.$imgUrl ='http://rwsuxarm.movek.net/api'
Vue.prototype.$imgUrl = 'https://www.movek.net/api/'//使用
// Vue.prototype.$baseUrl = 'http://192.168.1.8:8036'//sq本地




//配置公共方法
import common from './utils/index.js'
Vue.prototype.$noMultipleClicks = common.noMultipleClicks;
// Point of burial
Vue.prototype.$onPointOfBurial = common.onPointOfBurial;


// Vue.use(VMdPreview);
import VMdPreviewHtml from '@kangc/v-md-editor/lib/preview-html';
import '@kangc/v-md-editor/lib/style/preview-html.css';

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';

import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';


// highlightjs
import hljs from 'highlight.js';
// 引入使用主题的样式
// import '@kangc/v-md-editor/lib/theme/style/vuepress';
VMdEditor.use(githubTheme, {
  Hljs: hljs,
});
VMdPreview.use(githubTheme, {
  Hljs: hljs,
});
Vue.use(VMdPreviewHtml);
Vue.use(VMdEditor);
Vue.use(VMdPreview);


import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)
// markdown

// 字体
import './assets/font/font.css'
// 滚动
// import scroll from 'vue-seamless-scroll'

// import 'lib-flexible/flexible'//适配rem

// var _hmt = _hmt || [];
// (function() {
//   var hm = document.createElement("script");
//   hm.src = "https://hm.baidu.com/hm.js?64c2a0e102ac065b9d66aa2e589024de";
//   var s = document.getElementsByTagName("script")[0]; 
//   s.parentNode.insertBefore(hm, s);
// // })();
// router.beforeEach((to, from, next) => {
//   console.log(to.path, from, next, '路由拦截');
//     let callScript = document.querySelector("script[data-callType='callScript']")
//   if (to.path == '/login'&&callScript) {
//     console.log(callScript,'ouiuoiuiou');
//       window.location.reload()
   
//   } else {
//     if(to.path == '/login'&&!callScript){

//       next('/login');
//     }else{

//       next();
//     }

//     }
// })



import VueAMap from 'vue-amap';
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: ''
})
const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0];
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  if (htmlWidth >= 1980) {
    htmlWidth = 1980;
  }
  if (htmlWidth >= 1680) {
    htmlWidth = 1680;
  }

  htmlDom.style.fontSize = `${htmlWidth / 100}px`;
};
window.onresize = setHtmlFontSize;
setHtmlFontSize();


Vue.use(VueAMap);
Vue.use(Print)
Vue.prototype.$video = Video
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')


