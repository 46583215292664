/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */


export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

// 验证表单是否为空
export function validForm(form, _self) {
  let isAllHasVal = true
  let keys = []
  for (let i in form) {

    if (form[i] === '' || form[i] === null) {
      isAllHasVal = false
      keys.push(i)
    }
  }
  if (!isAllHasVal) {
    _self.$message.error('必填项不能为空')
    console.info(keys.join(',') + '不能为空')
    return isAllHasVal
  }
  return isAllHasVal
}
