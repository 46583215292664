import axios from 'axios'
import { Message } from 'element-ui'
const service = axios.create({
  // baseURL: process.env.VUE_APP_SHARE_URL,
  withCredentials:true,
  baseURL: 'https://www.movek.net/api',//使用
  timeout: 30000
})
service.interceptors.request.use(config => {
  if(config.url == '/front/login/registered'||config.url =='/front/login/login'){
    return config
  }else{
    let _token = localStorage.getItem('token') || ''
    config.headers['Authorization'] = _token
    return config
  }
}, error => {
  return Promise.reject(error)
})
service.interceptors.response.use(response => {
  const res = response.data
  if (res.ResultCode === 0 || res.ResultCode === 200) {
    return res
  } else if (res.ResultCode === 500) {
    return res
  } else {
    localStorage.removeItem("token")
    // Message.error(res.ResultInfo || 'Error')
    return Promise.reject(res.ResultInfo)
  }
}, error => {
  Message.error(error || 'Error');
  return Promise.reject(error)
})

export default service